import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, DialogActions, DialogContent, Grid, MenuItem, Tabs, Tab } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment, { Moment } from 'moment-timezone';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import EmailIcon from '@mui/icons-material/Email';

import apiClient from '../../utils/apiClient';
import { CalendarUser } from '../../declarations';
import Modal from '../../components/Modal';
import { ModalState } from '.';
import { PrimaryButton, TextButton } from '../../components/Button';
import Select from '../../components/Select';
import { Backdrop, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CreateUserModal from './CreateUserModal';
import PaymentIntentMethod from '../../enums/PaymentIntentMethod';

enum PaymentMethod {
	SAVED_CARD = 'SAVED_CARD',
	INVOICE = 'INVOICE',
	FREE = 'FREE',
}

const CreateAppointmentModal = ({ open, setOpen, provider, callAppointments, providerView = false }) => {
	const [date, setDate] = useState(null);
	const [services, setServices] = useState([]);
	const [selected, setSelected] = useState({
		slot: null,
		service: null,
		serviceItemId: [],
	});
	const [providers, setProviders] = useState([]);
	const [selectedProvider, setSelectedProvider] = useState(null);
	const [slots, setSlots] = useState(null);
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [showCreateUserModal, setShowCreateUserModal] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.SAVED_CARD);
	const [backdrop, setBackdrop] = useState(false);
	const [birthDate, setbirthDate] = useState(null);
	const [newData, setNewData] = useState({
		email: "",
		firstName: "",
		lastName: "",
		phone: "",
	});

	useEffect(() => {
		if (provider) {
			setSelectedProvider(provider.id);
			apiClient.get('/providers').then(res => {
				let p = res.filter(i => i.location && i.name);
				setProviders(p?.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1) || p);
			});
		}
	}, [provider])

	useEffect(() => {
		try {
			apiClient.get('/user').then(res => {
				const filtered = [];
				res.forEach(u => {
					if (!u.isDeleted) {
						filtered.push({ id: u.id, name: u.displayName, email: u.email });
					}
				});
				if (filtered.length) {
					const user = { id: filtered[0].id, name: filtered[0].displayName, email: filtered[0].email }
					setSelectedUser(user);
				}
				setUsers(filtered);
			});
		} catch (err) {
			toast.error('Error getting users.');
			console.error(err);
		}
	}, [provider])

	useEffect(() => {
		setDate(dayjs());
	}, [])

	useEffect(() => {
		if (selectedProvider && providers.length) {
			const selectedProviderIndex = providers.findIndex(i => i.id === selectedProvider);
			if (selectedProviderIndex !== -1) {
				apiClient.get(`/providers/svc-items?location=${providers[selectedProviderIndex]?.location.id}`).then(res => {
					setServices(res);
					if (res && res.length) {
						setSelected({ ...selected, service: res[0].id});
						getServiceItemIds(res[0].id)
					}
				});
			}
		}
	}, [selectedProvider, providers])

	useEffect(() => {
		if (selectedProvider && providers.length && services.length && selected.serviceItemId && selected.service) {
			const selectedProviderIndex = providers.findIndex(i => i.id === selectedProvider);
			if (selectedProviderIndex !== -1) {
				let momentOne = moment(date.format('YYYY-MM-DD')).tz(providers[selectedProviderIndex]?.timezone || 'America/Los_Angeles', true).startOf('day');
				apiClient.post('/appointments/availability', {
					serviceItemIds: selected.serviceItemId,
					startDate: momentOne.toISOString(),
					coordinates: providers[selectedProviderIndex]?.location.coordinates
				}).then(res => {
					let slots = []
					res.forEach(element => {
						if (element.providerId === selectedProvider) {
							slots.push(element)
						}
					});
					setSlots(slots);
				});
			}
		}
	}, [date, selected.serviceItemId, selectedProvider])

	const createAppointment = (event) => {
		event.preventDefault()
		setBackdrop(true);
		apiClient.post(`/i/appointments/`, {
			email: providerView ? newData.email : undefined,
			firstName: providerView ? newData.firstName : '',
			lastName: providerView ? newData.lastName : '',
			phone: providerView ? newData.phone : '',
			birthdate: providerView ? birthDate : undefined,
			serviceItemIds: selected.serviceItemId,
			provider: selectedProvider,
			startDate: selected.slot,
			user: !providerView && selectedUser.id || 0,
			sendInvoice: paymentMethod === PaymentMethod.INVOICE,
			isFree: paymentMethod === PaymentMethod.FREE
		}).then(res => {
			setBackdrop(false);
			setOpen(false);
			callAppointments();
			setSlots(null);
			setNewData({
				email: "",
				firstName: "",
				lastName: "",
				phone: "",
			})
			setbirthDate(null)
			toast.success('Created Appointment');
		}).catch(err => { setBackdrop(false); toast.error('Created Appointment Failed'); });
	}

	const createUser = (event) => {
		setShowCreateUserModal(true)
	}

	const filterUserOptions = createFilterOptions({
		stringify: ({ name, email }) => `${name} ${email}`
	});

	const selecCreatedUser = (user) => {
		setSelectedUser(user);
		setUsers([...users, user])
	}

	const changeData = (e) => {
		const { name, value } = e.target;
		setNewData({
			...newData,
			[name]: value,
		});
	}
	
	const getServiceItemIds = (id: any) => {
		const singleService = services.filter((service: any) => service?.id === id);
		if (singleService[0]?.serviceItemId){
			const singleServiceId = [singleService[0]?.serviceItemId]
			if (singleServiceId) {
				setSelected({...selected, service: id, serviceItemId: singleServiceId})
				return singleServiceId || []
			}
		} else {
			const bundleServiceItemIds = singleService[0]?.bundle?.serviceItems?.map((SI: any) => SI.id)
			if (bundleServiceItemIds){
				setSelected({...selected, service: id, serviceItemId: bundleServiceItemIds})
				return bundleServiceItemIds || []
			}
		}	
	}

	const handleServiceItems = (event: any) => {
		const service = event.target.value;
		getServiceItemIds(service)
	}

	return (
		<>
			<CreateUserModal
				open={showCreateUserModal}
				setOpen={setShowCreateUserModal}
				selecCreatedUser={selecCreatedUser}
			/>
			<Modal
				title="Create Appointment"
				open={open}
				onChange={() => {
					setOpen(false);
					setNewData({
						email: "",
						firstName: "",
						lastName: "",
						phone: "",
					});
					setbirthDate(null);
				}}>
				<DialogContent>
					<Box sx={{ p: 2 }}>
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={4}>
								<div style={{ marginLeft: '1rem' }}>User: </div>
							</Grid>
							<Grid item xs={8}>
								{providerView ? <TextField fullWidth label="Email" id="fullWidth" name='email' value={newData.email} onChange={changeData} aria-required /> :
									<Autocomplete
										value={selectedUser}
										id="combo-box-demo"
										options={users}
										onChange={(event, newValue) => {
											setSelectedUser(newValue);
										}}
										filterOptions={filterUserOptions}
										getOptionLabel={({ name, email }) => name || email}
										renderOption={({ name, email }) => {
											return name ? (
												<div>
													<div>
														{name}
													</div>
													<span>{email}</span>
												</div>
											) : <div>{email}</div>;
										}}
										renderInput={(params) => <TextField {...params} label="User" />}
									/>
								}
							</Grid>
						</Grid>
					</Box>
					{providerView && <>
						<Box sx={{ p: 2 }}>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item xs={4}>
									<div style={{ marginLeft: '1rem' }}>First Name: </div>
								</Grid>
								<Grid item xs={8}>
									<TextField fullWidth label="FirstName" id="fullWidth" name='firstName' value={newData.firstName} onChange={changeData} aria-required />
								</Grid>
							</Grid>
						</Box>
						<Box sx={{ p: 2 }}>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item xs={4}>
									<div style={{ marginLeft: '1rem' }}>Last Name: </div>
								</Grid>
								<Grid item xs={8}>
									<TextField fullWidth label="LastName" id="fullWidth" name='lastName' value={newData.lastName} onChange={changeData} aria-required />
								</Grid>
							</Grid>
						</Box>
						<Box sx={{ p: 2 }}>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item xs={4}>
									<div style={{ marginLeft: '1rem' }}>Birth Date: </div>
								</Grid>
								<Grid item xs={8}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											label="Date"
											value={birthDate}
											onChange={(newValue) => {
												setbirthDate(new Date(newValue));
											}}
											renderInput={(params) => <TextField style={{ width: "100%" }} {...params} />}
										/>
									</LocalizationProvider>
								</Grid>
							</Grid>
						</Box>
						<Box sx={{ p: 2 }}>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item xs={4}>
									<div style={{ marginLeft: '1rem' }}>Phone No: </div>
								</Grid>
								<Grid item xs={8}>
									<TextField fullWidth label="PhoneNo" id="fullWidth" name='phone' value={newData.phone} onChange={changeData} aria-required />
								</Grid>
							</Grid>
						</Box>
					</>
					}
					{!providerView && <Box sx={{ p: 2 }}>
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={4}>
								<div style={{ marginLeft: '1rem' }}>Providers: </div>
							</Grid>
							<Grid item xs={8}>
								<Select
									labelId="Service"
									onChange={(event) => { setSelectedProvider(event.target.value); setSlots(null); }}
									value={selectedProvider}>
									{providers.map((p, index) => {
										return p ? (<MenuItem style={{ fontFamily: 'Poppins' }} key={index} value={p.id}>
											{p.name}
										</MenuItem>) : null;
									})}
								</Select>
							</Grid>
						</Grid>
					</Box>
					}
					<Box sx={{ p: 2 }}>
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={4}>
								<div style={{ marginLeft: '1rem' }}>Date: </div>
							</Grid>
							<Grid item xs={8}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										label="Date"
										value={date}
										onChange={(newValue) => {
											setDate(newValue);
											setSlots(null);
										}}
										renderInput={(params) => <TextField style={{ width: "100%" }} {...params} />}
									/>
								</LocalizationProvider>
							</Grid>
						</Grid>
					</Box>
					{services &&
						<Box sx={{ p: 2 }}>
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item xs={4}>
									<div style={{ marginLeft: '1rem' }}>Services: </div>
								</Grid>
								<Grid item xs={8}>
									<Select
										labelId="Service"
										onChange={(event: any) => { handleServiceItems(event); setSlots(null); }}
										value={selected?.service}>
										{services.map((service, index) => {
											return service?.serviceItem ? (<MenuItem style={{ fontFamily: 'Poppins' }} key={index} value={service?.id}>
												{service?.serviceItem.subCategory ? service?.serviceItem.subCategory + " -> " + service?.serviceItem.name : service?.serviceItem.name}
											</MenuItem>) : (<MenuItem style={{ fontFamily: 'Poppins' }} key={index} value={service?.id}>
												{service?.bundle ? "Bundle -> " + service?.bundle.name : service?.bundle.name}
											</MenuItem>);
										})}
									</Select>
								</Grid>
							</Grid>
						</Box>
					}
					<Box sx={{ p: 2 }}>
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={4}>
								<div style={{ marginLeft: '1rem' }}>Available Slots: </div>
							</Grid>
							<Grid item xs={8}>
								{slots ? (selectedProvider && slots.length ? slots.map((slot, index) => {
									const selectedProviderIndex = providers.findIndex(i => i.id === selectedProvider);
									const tz = selectedProviderIndex === -1 ?
										provider?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone :
										providers[selectedProviderIndex]?.timezone || 'America/Los_Angeles';
									return (<TextButton
										key={index}
										label={moment(slot.earliestAppointmentTime).tz(tz).format('LT')}
										style={{
											backgroundColor: slot.earliestAppointmentTime === selected.slot ? 'grey' : '#00af9b',
											padding: "8px 10px",
											color: "white",
											margin: "5px",
											width: "90px",
										}}
										onClick={() => {
											setSelected({ ...selected, slot: slot.earliestAppointmentTime });
										}}
									/>)
								}) : "None")
									: (
										<div>
											<CircularProgress color="inherit" size={20} />
										</div>
									)}
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ p: 2 }}>
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={4}>
								<div style={{ marginLeft: '1rem' }}>Payment Method: </div>
							</Grid>
							<Grid item xs={8}>
								<ToggleButtonGroup
									sx={{ display: 'flex', flexWrap: 'wrap' }}
									value={paymentMethod}
									exclusive
									onChange={(event, value) => setPaymentMethod(value)}
									aria-label="text alignment">
									<ToggleButton sx={{ width: "112px" }} value={PaymentMethod.SAVED_CARD} aria-label="left aligned">
										Saved Card
									</ToggleButton>
									<ToggleButton sx={{ width: "112px" }} value={PaymentMethod.INVOICE} aria-label="left aligned">
										Invoice
									</ToggleButton>
									<ToggleButton sx={{ width: "112px" }} value={PaymentMethod.FREE} aria-label="left aligned">
										Free
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
					</Box>
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={backdrop}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				</DialogContent>
				<DialogActions>
					{!providerView && <PrimaryButton sx={{ p: 3 }} label="Create New User" onClick={createUser} />}
					<PrimaryButton sx={{ p: 3 }} label="Create Appointment" onClick={createAppointment} />
				</DialogActions>
			</Modal>
		</>
	);
};

export default CreateAppointmentModal;
