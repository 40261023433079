import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Chip, Hidden, FormControlLabel, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { toast } from 'react-toastify';

import './index.css';
import Layout from '../../components/Layout';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TextField, { Search } from '../../components/TextField';
import apiClient from '../../utils/apiClient';
import useDraft from '../../utils/useDraft';
import { PrimaryButton, TextButton } from '../../components/Button';
import ProviderServiceItemMapping from '../../components/ProviderServiceItemMapping';
import CommissionAndFees from '../../components/CommissionAndFees';
import { StrategyTypes } from '../../enums/Strategies';
import store, { actions } from '../../store';
import { attachStripeAccount } from '../../utils/settlementAccountHelpers';
import Calendar from '../../components/Calendar/Calendar';
import { Availability, CategoryTiming, ManageProvider, ServiceSelections } from '../../components/Provider';
import AppointModal from './AppointModal';
import SpecialDateModal from './SpecialDateModal';
import { CalendarUser } from '../../declarations';
import moment, { Moment } from 'moment-timezone';

import dayjs, { Dayjs } from 'dayjs';
import TextFieldMui from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {  DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import useStyles from './Provider.styles';
import CreateAppointmentModal from './CreateAppointmentModal';
import Switch from '../../components/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Holidays from 'date-holidays';

type Selection = {
  [id: number]: Service;
};

type Service = {
  name: string;
  percentage: number | null;
  commissionId: number;
};

export type ModalState = {
  open: boolean;
  title: string;
  context: any;
  tz: string;
};

export type SpecialDateModalState = {
  open: boolean;
  title: string;
  selectedSpecialDate: any;
  providerId: number;
  locationId: number;
};

export enum AppointmentStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  LATE_CANCELLED = "LATE_CANCELLED",
  FILMING = 'FILMING'
}

function ManageProviders(props) {
  let hd = new Holidays('US');
  let getCurrentYear = moment().year();
  let HolidayList = hd.getHolidays(Number(getCurrentYear)).filter(H => H.type === "public" || H.name === "Mother's Day");
  const history = useHistory();
  const isAdmin = useSelector((state: store) => state.user.isAdmin);
  const onboardingProvider = useSelector((state: store) => state.onboarding.provider);
  const [open, setOpen] = useState(false);
  const [showCreateAppointmentModal, setShowCreateAppointmentModal] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [providers, setProviders] = useState([]);
  const [provider, setProvider, residualProvider, updateProvider, handleProviderInput] = useDraft({id: null});
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    title: 'Appointment',
    context: null,
    tz: provider.timezone || 'America/Los_Angeles',
  });
  const [specialDateModalState, setSpecialDateModalState] = useState<SpecialDateModalState>({
    open: false,
    title: 'Appointment',
    selectedSpecialDate: null,
    providerId: null,
    locationId: null
  });
  const [services, setServices] = useState<Selection>({});
  const [serviceCategories, setServiceCategories] = useState(null);
  const [datePicked, setDatePicked] = React.useState<DateRange<Dayjs>>([null, null]);
  const [specialDates, setSpecialDates] = useState<any[]>([{}]);

  const classes = useStyles();

  const loading = open && options.length === 0;

  const [location, setLocation, residualLocation, updateLocation, handleLocationInput] = useDraft({});
  
  const [credentials, setCredentials, residualCredentials, updateCredentials, handleCredentialsInput] = useDraft({});

  const [appointments, setAppointments] = useState([]);

  const [stripeAccount, setStripeAccount, residualStripeAccount, updateStripeAccount, handleStripeAccountInput] =
    useDraft({});

  const [selectedCalendarUser, setSelectedCalendarUser] = useState<CalendarUser | null>(null);
  const [lateCancel, setlateCancel] = useState(0);
  const [refundOption, setRefundOption] = useState(0);
  const [holidayList, setHolidayList] = useState(HolidayList || [])
  const [holidayListData, setHolidayListData] = useState([]);
  const [filters, setFilters] = useState({
    country: null,
    city: null,
    provider: null,
  });

  useEffect(() => {
    let renameHolidayList = holidayList.filter(item => item.name !== "New Year's Day (substitute day)").map((item)=>{
        if(item.name === "New Year's Day"){
          item.name = "New Year's Eve";
        }else if(item.name === "Christmas Day"){
          item.name = "Christmas Eve";
        }else if(item.name === "Mother's Day"){
          item.name = "American Mother's Day";
        }
        return item;
    });
    setHolidayList(renameHolidayList);
  }, [])

  useEffect(() => {
    if (holidayList && specialDates) {
      const holidayData = holidayList?.map((data) => {
        const isSpecialDate = specialDates.some((v) =>
          moment(v.startDate).isSame(moment(data.date), 'day') &&
          moment(v.endDate).isSame(moment(data.date), 'day')
        );
  
        return { ...data, checked: isSpecialDate };
      });

      setHolidayListData(holidayData);
    }
  }, [holidayList,specialDates])

  const handleCalEventClick = (calEvent) => {
    const { data } = calEvent.event.extendedProps;
    const { appointment } = data;
    setModalState({
      open: true,
      title: `Appointment - ${appointment.user.email}`,
      context: appointment,
      tz: provider.timezone || 'America/Los_Angeles',
    });
  };

  const goToInviteProviderView = () => history.push('/providers/provider/invite');

  const handleCancelAppt = async () => {
    const { context } = modalState;
    if (context) {
      const { groupId } = context;
      try {
        const appointmentIndex = appointments.findIndex(({ groupId: ithGroupId }) => ithGroupId === groupId);
        await apiClient.delete(`/appointments/${groupId}?latecancelfee=${lateCancel}&refundOption=${refundOption}`);
        const _appointments = _.cloneDeep(appointments);
        if (appointmentIndex !== -1) {
          _appointments[appointmentIndex].status = Boolean(Number(lateCancel)) ? AppointmentStatus.LATE_CANCELLED : AppointmentStatus.CANCELLED;
        }
        setAppointments(_appointments);
        setModalState({
          open: false,
          title: '',
          context: null,
          tz: provider.timezone || 'America/Los_Angeles',
        });
        toast.success('Appointment Cancelled');
      } catch (err) {
        toast.error('Error cancelling appointment');
      }
    }
  };

  const callAppointments = async () => {
    const appts = await apiClient.get('/appointments', {
      provider: provider.id,
      group: true,
    });
    const tz = provider.timezone || 'America/Los_Angeles'
    appts.map((appt: any) => appt.startDate = moment(appt.startDate).tz(tz).format())
    setAppointments(appts);
  }

  useEffect(() => {
    if (props.location.state && !props.location.state.fromViewAsAdmin) {
      exit();
    }
    (async () => {
      const fetchedServices = await apiClient.get('/svc-category');
      setServiceCategories(fetchedServices);
    })();
  }, []);

  const fetchCommission = useCallback(async () => {
    try {
      let updatedServices = {};
      for (const service of serviceCategories) {
        updatedServices[service.id] = {};
        updatedServices[service.id] = { name: service.name, percentage: null, commissionId: null };
      }
      const commissions = await apiClient.get('/commission', {
        providerId: provider.id,
      });
      for (const commission of commissions[0]) {
        updatedServices[commission.serviceCategory.id].percentage = commission.percentage;
        updatedServices[commission.serviceCategory.id].commissionId = commission.id;
      }
      setServices(updatedServices);
    } catch (error) {
      toast.error('Failed to get commission from provider');
    }
  }, [provider.id, serviceCategories]);

  useEffect(() => {
    if (!serviceCategories || !_.get(provider, 'id')) {
      return;
    }
    if (provider.id) {
      fetchCommission();
    }
  }, [fetchCommission, provider.id, serviceCategories]);

  useEffect(() => {
    (async () => {
      const user = modalState.context?.user;
      if (user) {
        const selectedUser = await apiClient.get(`/user/${user.firebaseUid}`);
        setSelectedCalendarUser(selectedUser);
      } else {
        setSelectedCalendarUser(null);
      }
    })();
  }, [modalState]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    (async () => {
      const providers = await apiClient.get('/providers');
      if (active) {
        setOptions(providers);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    const loadStripeAccount = async (settlementAccountId: number) => {
      const stripeAccount: { id: string } & any = await apiClient.get(`/settlement-accounts/${settlementAccountId}`);
      setStripeAccount(stripeAccount);
    };
    if (provider.id) {
      let { location, credentials } = provider;
      let updatedLocation = _.cloneDeep(location);
      if (location && location.coordinates) {
        const { lat, lng } = location.coordinates;
        updatedLocation.coordinates = `${lat}, ${lng}`;
      }

      setLocation(updatedLocation);
      setCredentials(credentials);
      callAppointments();

      (async () => {
        const specialDates = await apiClient.get(`/special-date/provider/${location?.id}`)
        setSpecialDates(specialDates);
      })();

      if (location?.settlementAccount?.id) {
        (async () => await loadStripeAccount(location.settlementAccount.id))();
      }
    } else {
      setLocation({});
      setCredentials({});
      setAppointments([]);
      setStripeAccount({});
    }
  }, [provider.id, setCredentials, setLocation, setStripeAccount]);

  useEffect(() => {
    (async () => {
      if (_.has(onboardingProvider, 'id')) {
        return setProvider(onboardingProvider);
      }
      setProvider({});
      const listProviders = await apiClient.get('/providers');
      setProviders(listProviders);

      let countryList = listProviders.map((i) => i?.location?.country).filter((v, i, a) => a.indexOf(v) === i);

      let cityList = listProviders.map((i) => i?.location?.city).filter((v, i, a) => a.indexOf(v) === i);

      setCountries(countryList);
      setCities(cityList);
    })();
  }, [onboardingProvider, setProvider]);

  useEffect(() => {
    let cityList = providers
      .filter((x) => (filters.country ? x?.location?.country === filters.country : x))
      .map((i) => i?.location?.city)
      .filter((x, i, a) => a.indexOf(x) === i);

    setCities(cityList);
  }, [filters.country, providers]);

  useEffect(() => {
    if (!datePicked[0] || !datePicked[1]) return

    const newSpecialDate = {
      startDate: datePicked[0].format('YYYY/MM/DD'),
      endDate: datePicked[1].format('YYYY/MM/DD'),
      locationId: location.id
    }

    const saveSpecialDate = async () => {
      try {
        const savedSpecialDate = await apiClient.post(`/special-date`, newSpecialDate)
        const updatedSpecialDates = [...specialDates || [], savedSpecialDate]
        setSpecialDates(updatedSpecialDates)
        toast.success(`Added Special Date ${newSpecialDate.startDate} - ${newSpecialDate.endDate}`);
      } catch(err) {
        toast.error(err.response.data.message);
        console.error(err)
      }
    };

    saveSpecialDate()
    setDatePicked([null,null]);
  }, [datePicked]);

  const deleteSpecialDate = async (specialDate: any) => {
    const updatedSpecialDates = specialDates.filter((item, i) => item.id !== specialDate.id)

    try {
      await apiClient.delete(`/special-date/${specialDate.id}`)
      toast.success(`Removed Special Date ${specialDate.startDate} - ${specialDate.endDate}`);
      setSpecialDates(updatedSpecialDates)
    } catch(err) {
      console.error(err)
    }
  }

  const specialDatesHandleClick = (specialDate) => {
    setSpecialDateModalState({
      open: true,
      title: `Available services on period ${specialDate.startDate} - ${specialDate.endDate}`,
      selectedSpecialDate: specialDate,
      providerId: provider.id,
      locationId: location.id,
    });

  }

  const hasItemsSelected = (specialDate) =>
    specialDate?.specialDateServiceItems && specialDate?.specialDateServiceItems?.length > 0;

  const chipStyle = (specialDate) => {
    let root = classes.chip;
    const serviceItemsSelected =
      specialDate?.specialDateServiceItems && specialDate?.specialDateServiceItems?.length > 0;
    if (!serviceItemsSelected) root = classes.chipDisabled;

    return { root };
  };

  const callLocation = async (coordinates, updatedLocation) => {
    updateLocation('coordinates', coordinates);
    coordinates = (coordinates || '').split(',').reduce((acc, curr, index) => {
      const coord = parseFloat(curr.trim());
      acc[index === 0 ? 'lat' : 'lng'] = coord;
      return acc;
    }, {});
    let newupdatedLocation = _.cloneDeep(updatedLocation || location);
    newupdatedLocation.coordinates = coordinates;
    newupdatedLocation.isEMAILNotificationsEnabled = location.isEMAILNotificationsEnabled;
    actions.setLocation(newupdatedLocation);
    actions.setProvider(updateProvider('location', newupdatedLocation));
    if (typeof location.billingContactEmail === 'string')
      location.billingContactEmail = location.billingContactEmail.replace(/\s/g, '').split(',');
    await apiClient.put(`/locations/${location.id}`, {
      ...location,
      coordinates,
      ...(updatedLocation?.settlementAccount && { settlementAccount: updatedLocation.settlementAccount }),
    });
  };

  const save = async () => {
    let errors = [];
    const updatedLocation = await handleAttachStripeAccount();

    // Update Location
    try {
      if (location && location.id) {
        if (location.streetAddress || location.city || location.state || location.country) {
          const { streetAddress, city, state, country } = location;
          const geocoder = new (window as any).google.maps.Geocoder();
          const fullAddress = [streetAddress, city, state, country].join(', ');
          geocoder.geocode({ address: fullAddress }, function (results, status) {
            if (status == 'OK') {
              if (results.length) {
                try {
                  const result = results[0];
                  const { geometry } = result;
                  const newcoordinates = `${geometry.location.lat()},${geometry.location.lng()}`;
                  callLocation(newcoordinates, updatedLocation);
                } catch (err) {
                  errors.push(err);
                }
              }
            } else {
              toast.error('Address is invalid');
              console.error('error');
              return;
            }
          });
        }else{
          toast.error("Address is required");
        }
      }
    } catch (err) {
      errors.push(err);
    }
    //Update Credentials
    try {
      if (credentials && credentials.id) {
        updateProvider('credentials', credentials);
        await apiClient.put(`/credentials/${credentials.id}`, credentials);
      }
    } catch (err) {
      errors.push(err);
    }
    //Update Provider
    try {
      const newupdatedProvider = _.cloneDeep(provider);
      newupdatedProvider.location = location;
      await apiClient.put(`/providers/${provider.id}`, newupdatedProvider);
    } catch (err) {
      errors.push(err);
    }
    // Update Commission
    try {
      for (const serviceCategoryId in services) {
        const service = services[serviceCategoryId];
        if (service.percentage === null) {
          continue;
        }
        if (service.commissionId === null) {
          await apiClient.post('/commission', {
            serviceCategoryId: serviceCategories.find((s) => s.id === Number(serviceCategoryId)).id,
            providerId: provider.id,
            percentage: service.percentage,
          });
        } else {
          await apiClient.put(`/commission/${service.commissionId}`, {
            percentage: service.percentage,
          });
        }
      }
    } catch (err) {
      errors.push(err);
    }

    if (!errors.length) {
      toast.success('Saved Provider');
    } else {
      toast.error('Failed to update provider');
      errors.forEach(console.error);
    }
  };

  const handleAttachStripeAccount = async () => {
    if (stripeAccount.id) {
      return await attachStripeAccount(location.id, stripeAccount.id);
    }
  };

  const setProviderState = (i: any) => {
    setProvider(i || {});
    actions.setProvider(i);
    actions.setLocation(i.location);
  };

  const exit = () => {
    setProvider({});
    setLocation({});
    actions.setProvider({});
    actions.setLocation({});
    setServices({});
  };

  const handleSwitchInput = (attr: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    updateLocation(attr, value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>,value: any,index: any) => {
    if (event.target.checked) {
      let datePickedValue = event.target.value;
      setHolidayListData([...holidayListData.slice(0, index), { ...value, checked: event.target.checked }, ...holidayListData.slice(index + 1, holidayListData.length)])
      setDatePicked([dayjs(datePickedValue), dayjs(datePickedValue)]);
    } else {
      specialDates && specialDates.map((specialDate) => {
        if (moment(specialDate.startDate).isSame(specialDate.endDate) && moment(specialDate.endDate).isSame(value.date)) {
          deleteSpecialDate(specialDate)
        }
      })
    }
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <Layout>
      <CreateAppointmentModal
        open={showCreateAppointmentModal}
        setOpen={setShowCreateAppointmentModal}
        provider={provider}
        callAppointments={callAppointments}
      />
      <AppointModal
        provider={provider}
        modalState={modalState}
        setModalState={setModalState}
        selectedCalendarUser={selectedCalendarUser}
        handleCancelAppt={handleCancelAppt}
        callAppointments={callAppointments}
        lateCancel={lateCancel}
        setlateCancel={setlateCancel}
        refundOption={refundOption}
        setRefundOption={setRefundOption}
      />

      <SpecialDateModal
        modalState={specialDateModalState}
        setModalState={setSpecialDateModalState}
      />

      {!_.has(provider, 'id') && (
        <>
          <h4>Providers</h4>
          {isAdmin && (
            <div className="upk-form mt-2 mb-3" style={{ backgroundColor: 'white' }}>
              <div className="header">
                <div className="title">Manage Providers</div>
                <div>
                  <PrimaryButton
                    label="Invite Provider"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => goToInviteProviderView()}
                  />
                </div>
              </div>
            </div>
          )}
          <div style={{ margin: '2rem 0', display: 'flex' }}>
            <div style={{ width: '30%', float: 'left' }}>
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option || ""}
                getOptionSelected={(option, value) => value}
                style={{ width: '80%', float: 'left' }}
                onChange={(event: any, selectedCountry: any) => setFilters({ ...filters, country: selectedCountry })}
                renderInput={(params) => <TextField {...params} label="Country" />}
              />
              <PlayArrowIcon
                fontSize="large"
                style={{ color: '#C4C4C4', width: '20%', float: 'left', marginTop: '.5rem' }}
              />
            </div>
            <div style={{ width: '30%', float: 'left' }}>
              <Autocomplete
                options={cities}
                getOptionLabel={(option) => option || ""}
                getOptionSelected={(option, value) => value}
                style={{ paddingRight: '3rem' }}
                //disabled={filters.country === null}
                onChange={(event: any, selectedCity: any) => setFilters({ ...filters, city: selectedCity })}
                renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
              />
            </div>
            <div style={{ width: '40%', float: 'right' }}>
              <Search
                label="Provider Name"
                style={{ width: '100%' }}
                onChange={(e) => setFilters({ ...filters, provider: e.target.value })}
              />
            </div>
          </div>

          <Grid container style={{ marginLeft: '-1.5%', paddingBottom: '2rem' }}>
            {providers
              .filter((i) => (filters.provider ? i.name.toLowerCase().includes(filters.provider.toLowerCase()) : i))
              .filter((i) => (filters.country ? i.location.country === filters.country : i))
              .filter((i) => (filters.city ? i.location.city === filters.city : i))
              .map((i) => (
                <Grid key={i.id} item xs={6} md={3} style={{ padding: '1% 1.5%' }}>
                  <div className="search-tile" onClick={() => setProviderState(i)}>
                    <div style={{ margin: '15px' }}>
                      <div className="search-tile image" style={{ backgroundImage: `url(${i.coverImageURL})` }} />
                    </div>
                    <div style={{ margin: '1rem' }}>
                      <b>{i.name}</b>
                      <br />
                      <p>
                        {i.location
                          ? `${i.location.city}, ${i.location.state}, ${i.location.country}`
                          : 'Unknown Location'}
                      </p>
                    </div>
                  </div>
                </Grid>
              ))}
          </Grid>
        </>
      )}

      {provider?.id && (
        <div className="upk-form mt-2 mb-3" style={{ backgroundColor: 'white' }}>
          <div className="header">
            <div className="title">Manage Providers</div>
            <div style={{ marginLeft: 'auto' }}>
              <TextButton label="Exit" style={{ marginRight: '1rem' }} onClick={exit} />
              <PrimaryButton
                label="View as provider"
                style={{ marginRight: '0.5rem' }}
                onClick={() => {
                  actions.setViewAsProvider();
                  history.push({ pathname: '/provider/overview' });
                }}
              />
              <PrimaryButton label="Save" onClick={() => save()} />
            </div>
          </div>

          <div className="body">
            {/*<Autocomplete
                            fullWidth={true}

                            className="mb-2"
                            style={{ width: 300 }}
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            onChange={(event: any, selectedProvider: any) => setProvider(selectedProvider || {})}
                            getOptionSelected={(option, value) => option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            options={options}
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Providers"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />*/}
            {/* end autocomplete */}
            {/* --------------- Provider Header ----------------- */}
            <div className="provider-header mb-1">
              <div className="cover-image" style={{ backgroundImage: `url(${residualProvider('coverImageURL')})` }} />
              <div className="title-banner" />
              <div className="title">{residualProvider('name')}</div>
            </div>
            {/* --------------- End Provider Header ----------------- */}
            {/* --------------- Provider ----------------- */}
            <ManageProvider
              provider={provider}
              handleProviderInput={handleProviderInput}
              residualProvider={residualProvider}
              residualLocation={residualLocation}
              updateLocation={updateLocation}
              updateProvider={updateProvider}
            />
            {/* --------------- End Provider ----------------- */}
            {/* --------------- Location ----------------- */}
            {location?.id && (
              <div className="border mb-1">
                <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                  {' '}
                  Manage Location
                </h4>

                <TextField
                  id="tax-rate"
                  marginTop={true}
                  label="Tax Rate (in decimal - example: 0.12)"
                  fullWidth={true}
                  onChange={handleLocationInput(`taxRate`)}
                  value={residualLocation('taxRate')}
                  InputProps={{ inputProps: { min: 0, max: 1 } }}
                  type="number"
                />

                <TextField
                  id="billing-contact-email"
                  marginTop={true}
                  label="Billing Contact Email (Add multiple emails separated with comma)"
                  fullWidth={true}
                  onChange={handleLocationInput(`billingContactEmail`)}
                  value={residualLocation('billingContactEmail') || ''}
                />

                <FormControlLabel
                  label="Enable Email Notification?"
                  style={{marginBottom: '-10px'}}
                  control={
                    <Switch checked={residualLocation('isEMAILNotificationsEnabled') || false} onChange={handleSwitchInput('isEMAILNotificationsEnabled')} />
                  }
                />

                <TextField
                  id="svc-item-name"
                  marginTop={true}
                  label="Steet Address"
                  fullWidth={true}
                  onChange={handleLocationInput(`streetAddress`)}
                  value={residualLocation('streetAddress')}
                />

                <TextField
                  id="svc-item-name"
                  marginTop={true}
                  label="addressLine2"
                  fullWidth={true}
                  onChange={handleLocationInput(`addressLine2`)}
                  value={residualLocation('addressLine2')}
                />

                <TextField
                  id="svc-item-name"
                  marginTop={true}
                  label="ZIP"
                  fullWidth={true}
                  onChange={handleLocationInput(`zip`)}
                  value={residualLocation('zip')}
                />

                <TextField
                  id="svc-item-name"
                  marginTop={true}
                  label="City"
                  fullWidth={true}
                  onChange={handleLocationInput(`city`)}
                  value={residualLocation('city')}
                />

                <TextField
                  id="svc-item-name"
                  marginTop={true}
                  label="State"
                  fullWidth={true}
                  onChange={handleLocationInput(`state`)}
                  value={residualLocation('state')}
                />

                <TextField
                  id="svc-item-name"
                  marginTop={true}
                  label="Country"
                  fullWidth={true}
                  onChange={handleLocationInput(`country`)}
                  value={residualLocation('country')}
                />

                <TextField
                  id="svc-item-name"
                  disabled={true}
                  marginTop={true}
                  label="Coordinates"
                  fullWidth={true}
                  onChange={handleLocationInput(`coordinates`)}
                  value={residualLocation('coordinates')}
                  placeholder="49.02930,-123.0128"
                />
              </div>
            )}
            {/* --------------- End Location ----------------- */}

            {/* --------------- Start Special Dates ----------------- */}

            {location?.id && (
              <div className="border mb-1">
                <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                  {' '}
                  Special Dates
                </h4>
                <Box className={classes.formBlock}>
                  {/* @ts-ignore */}
                  <Hidden smUp>
                    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: 'Start', end: 'End' }}>
                      <MobileDateRangePicker
                        value={datePicked}
                        onChange={(newValue) => setDatePicked(newValue)}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextFieldMui {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextFieldMui {...endProps} />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </Hidden>

                  {/* @ts-ignore */}
                  <Hidden xsDown>
                    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: 'Start', end: 'End' }}>
                      <DesktopDateRangePicker
                        value={datePicked}
                        onChange={(newValue) => setDatePicked(newValue)}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextFieldMui {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextFieldMui {...endProps} />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </Hidden>

                  <Box sx={{ display: 'flex' }}>
                    <FormControl component="fieldset" variant="standard">
                      <h4 className="heading-small text-muted" style={{marginTop: "5px"}}>
                          Holiday List
                      <Typography>Holidays are enabled by default to provide service on holiday. If you would not like to provide treatments during holidays, you can opt out by selecting the option below.</Typography>
                      </h4>
                      <FormGroup>
                        {holidayListData && holidayListData?.map((v, i) => {
                          return (
                            <>
                              <FormControlLabel key={i}
                                control={
                                  <Checkbox checked={v.checked} value={v.date} onChange={(e) => handleChange(e, v, i)} />
                                }
                                label={v.name}
                              />
                            </>
                          )
                        })
                        }
                      </FormGroup>
                    </FormControl>
                  </Box>

                  <Box display="flex" className={classes.selectedDates} flexWrap="wrap" >
                    {specialDates &&
                      specialDates.map((specialDate, index) => {
                        if (hasItemsSelected(specialDate))
                          return (
                            <Chip
                              onClick={() => specialDatesHandleClick(specialDate)}
                              label={`${specialDate.startDate} - ${specialDate.endDate}`}
                              onDelete={() => deleteSpecialDate(specialDate)}
                              classes={chipStyle(specialDate)}
                              key={index}
                              deleteIcon={<CloseIcon />}
                            />
                          )})}
                  </Box>
                  <h1 className="heading-small text-muted" style={{ marginLeft: '0' }}>
                    {' '}
                    {specialDates.length > 0 ? "Provider is not available on these holidays" : "No holidays have been selected yet"}
                  </h1>
                  <Box display="flex" className={classes.selectedDates} flexWrap="wrap" >
                    {specialDates &&
                      specialDates.map((specialDate, index) => {
                        if (!hasItemsSelected(specialDate))
                          return (
                            <Chip
                              onClick={() => specialDatesHandleClick(specialDate)}
                              label={`${specialDate.startDate} - ${specialDate.endDate}`}
                              onDelete={() => deleteSpecialDate(specialDate)}
                              classes={chipStyle(specialDate)}
                              key={index}
                              deleteIcon={<CloseIcon />}
                            />
                          )})}
                  </Box>
                </Box>
              </div>
            )}
            {/* --------------- End Special Dates ----------------- */}

            {
              /* --------------- Stripe Account ----------------- */
              isAdmin &&
              (!!residualLocation('stripeAccount.id') ? (
                <div className="border mb-1">
                  <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                    {' '}
                    Manage Stripe Account
                  </h4>
                  <TextField
                    id="stripe-account-id"
                    // disabled={true}
                    marginTop={true}
                    label="Stripe Account ID"
                    fullWidth={true}
                    value={residualStripeAccount('id')}
                  />
                  <TextField
                    id="stripe-account-name"
                    // disabled={true}
                    marginTop={true}
                    label="Stripe Account Name"
                    fullWidth={true}
                    value={residualStripeAccount('business_profile.name')}
                  />
                </div>
              ) : (
                <div className="border mb-1">
                  <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                    {' '}
                    Manage Stripe Account
                  </h4>
                  <TextField
                    id="stripe-account-id"
                    // disabled={false}
                    marginTop={true}
                    label="Stripe Account ID"
                    fullWidth={true}
                    onChange={handleStripeAccountInput('id')}
                    value={residualStripeAccount('id')}
                  />
                  <TextField
                    id="stripe-account-name"
                    // disabled={true}
                    marginTop={true}
                    label="Stripe Account Name"
                    fullWidth={true}
                    value={_.get(stripeAccount, 'business_profile.name')}
                  />
                </div>
              ))
              /* --------------- End Stripe Account ----------------- */
            }
            {
              /* --------------- Commission and Fees ----------------- */
              <div className="border mb-1">
                <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                  {' '}
                  Commission and Fees
                </h4>
                <CommissionAndFees services={services} setServices={setServices} residualProvider={residualProvider} updateProvider={updateProvider}/>
              </div>
              /* --------------- End Commission and Fees ----------------- */
            }
            {/* --------------- Availability ----------------- */}
            {location?.id && provider.strategy !== StrategyTypes.MANUAL && (
              <Availability
                residualLocation={residualLocation}
                updateLocation={updateLocation}
                handleLocationInput={handleLocationInput}
              />
            )}
            {/* --------------- End Availability ----------------- */}

            {/* --------------- Credentials ----------------- */}
            {credentials?.id && provider.strategy !== StrategyTypes.MANUAL && (
              <div className="border mb-1">
                <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                  {' '}
                  Manage Credentials
                </h4>

                <TextField
                  marginTop={true}
                  label="Username"
                  fullWidth={true}
                  onChange={handleCredentialsInput(`username`)}
                  value={residualCredentials('username')}
                />

                <TextField
                  marginTop={true}
                  label="Password"
                  fullWidth={true}
                  onChange={handleCredentialsInput(`password`)}
                  value={residualCredentials('password')}
                />
              </div>
            )}
            {/* --------------- End Credentials ----------------- */}

            {provider.strategy === StrategyTypes.MANUAL && (
              <ServiceSelections providerId={provider.id} locationId={provider.location.id} />
            )}
            {/* --------------- Service Mapping ----------------- */}
            {location?.id && (
              <div className="border mb-1">
                {provider.strategy === StrategyTypes.MANUAL ? (
                  <CategoryTiming provider={provider} />
                ) : (
                  <>
                    <h4 className="heading-small text-muted mb-1" style={{ marginLeft: 'auto' }}>
                      {' '}
                      Manage Service Mappings
                    </h4>
                    <ProviderServiceItemMapping providerId={provider.id} locationId={provider.location.id} />
                  </>
                )}
              </div>
            )}
            {/* --------------- End Service Mapping ----------------- */}
            {appointments &&
              <Calendar
                appointments={appointments}
                clickHandler={handleCalEventClick}
                setShowCreateAppointmentModal={setShowCreateAppointmentModal}
              />}
          </div>
        </div>
      )}
    </Layout>
  );
}

export default ManageProviders;
