import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Typography, ButtonBase } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import apiClient from '../../utils/apiClient';
import { concatString } from '../../utils';
import { PrimaryButton, TextButton, AddButton } from './../../components/Button';
import { Accordion, AccordionSummary } from './Accordion';
import TextField from '../../components/TextField';
import ChipsArray from '../../components/ChipArray';
import useStyles from './index.styles';

const BundleItemManagement = ({ serviceCategory }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    bundles: [],
  });

  useEffect(() => {
    (async () => {
      setState({
        ...state,
        isLoading: true,
      });
      const [bundleList] = await Promise.all([apiClient.get('/bundle')]);
      setState({
        ...state,
        isLoading: false,
        bundles: bundleList,
      });
    })();
  }, []);

  const handleBundleChange = (index, prop) => (e) => {
    const value = e?.target?.value || e;
    const newBundles = state.bundles.map((bundle, ithIndex) => {
      if (ithIndex === index) {
        return {
          ...bundle,
          [prop]: value,
        };
      } else {
        return bundle;
      }
    });
    setState({
      ...state,
      bundles: newBundles,
    });
  };

  const handleSave = (i) => async (e) => {
    let bundle = { ...state.bundles[i] };

    bundle.serviceItems = bundle.serviceItems.map((svcItem) => svcItem.id);

    let newBundle;
    try {
      if (typeof bundle.id === 'number') {
        newBundle = await apiClient.put(`/bundle/${bundle.id}`, bundle);
        toast.success('Saved Bundle');
      } else {
        newBundle = await apiClient.post(`/bundle/`, bundle);
        setState({
          ...state,
          bundles: state.bundles.map((b, index) => {
            if (index === i) {
              return {
                ...b,
                id: newBundle.id,
              };
            } else {
              return b;
            }
          }),
        });
        toast.success('Successfully created new Bundle');
      }
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
  };

  const handleRemove = (i) => async (e) => {
    let bundle = { ...state.bundles[i] };
    try {
      if (typeof bundle.id === 'number') {
        await apiClient.delete(`/bundle/${bundle.id}`, bundle);
      }
      setState({
        ...state,
        bundles: state.bundles.filter((ithBundle, index) => {
          return i !== index;
        }),
      });
      toast.success('Deleted Bundle');
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
  };

  const handleAddNewBundle = () => {
    setState({
      ...state,
      bundles: [
        ...state.bundles,
        {
          name: 'New Bundle',
          serviceItems: [],
        },
      ],
    });
  };

  const displayServiceItems = () => {
    return _.flatMap(serviceCategory,(item) => {
      return (item?.serviceItems || []).map((svcItem) => {
        return {
          ...svcItem,
          name: concatString([item.name, svcItem.subCategory, svcItem.name]),
        };
      })
    });
  };

  return (
    <>
      {state.bundles.map((bundle, i) => {
        return (
          <Accordion key={`bundle-${i}`}>
            <AccordionSummary className={classes.serviceItem}>
              <Typography variant="body2" className="item-title">
                {bundle.name}
              </Typography>
              <ButtonBase className={classes.btnEdit}>
                <EditIcon fontSize="small" />
              </ButtonBase>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: '100%' }}>
                <TextField
                  style={{ margin: '1rem 0 2rem 0' }}
                  label="Bundle Name"
                  fullWidth={true}
                  onChange={handleBundleChange(i, 'name')}
                  value={bundle.name}
                />
                <ChipsArray
                  label="Service Items In Bundle"
                  onChange={(e, selectedServiceItems) => handleBundleChange(i, 'serviceItems')(selectedServiceItems)}
                  options={displayServiceItems()}
                  value={bundle?.serviceItems || []}
                  placeholder="Service Items"
                />
                <div className="mt-2">
                  <PrimaryButton label="Save" onClick={handleSave(i)} />
                  <TextButton style={{ marginLeft: '1rem' }} label="Remove" onClick={handleRemove(i)} />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <AddButton className="mt-2" label="+ Add New Bundle" onClick={handleAddNewBundle} />
    </>
  );
};

export default BundleItemManagement;
