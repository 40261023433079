import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { useSelector } from 'react-redux';
import store, { actions } from '../../store';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AddButton, PrimaryButton } from '../../components/Button';
import { useHistory } from 'react-router-dom';
import TextField from '../../components/TextField';
import apiClient from '../../utils/apiClient';
import _ from 'lodash';
import useDraft from '../../utils/useDraft';
import { attachStripeAccount, updateSettlementAccountDetails, updateSettlementExternalAccount } from '../../utils/settlementAccountHelpers';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

const BankInformation = () => {
    const history = useHistory();
    const isAdmin = useSelector((state: store) => state.user.isAdmin);
    const provider = useSelector((state: store) => state.onboarding.provider);
    const viewAsProvider = useSelector((state: store) => state.user.viewAsProvider);
    const [providers, setProviders] = useState<null | any[]>(null);
    const [currentStripeId, setCurrentStripeId] = useState<string | any>();
    const [udpatingStripeId, setUpdatingStripeId] = useState<boolean | any[]>(false);
    const [udpateStripeIdProcessing, setUdpateStripeIdProcessing] = useState<boolean | any[]>(false);
    const [bankUpdating, setBankUpdating] = useState<boolean | any[]>(false);
    const [bankUpdateProcessing, setBankUpdateProcessing] = useState<boolean | any>(false);
    const [settlemntAccountUpdateProcessing, setSettlemntAccountUpdateProcessing]= useState<boolean | any>(false);
    const [currentBank, setCurrentBank] = useState<object | any>();
    const [stripeAccount, setStripeAccount, residualStripeAccount, updateStripeAccount, handleStripeAccountInput] = useDraft({});
    const [location, setLocation, residualLocation, updateLocation, updateProvider, handleLocationInput] = useDraft({});

    function fetchLatestSettlementAccount() {
        const loadStripeAccount = async (settlementAccountId: number) => {
            const stripeAccount: { id: string } & any = await apiClient.get(`/settlement-accounts/${settlementAccountId}`);
            setStripeAccount(stripeAccount);
            setCurrentStripeId(stripeAccount?.id);
            setCurrentExternalBankDetails(stripeAccount)
        };
        if (provider.id) {
            let { location } = provider;
            let updatedLocation = _.cloneDeep(location);
            if (location && location.coordinates) {
                const { lat, lng } = location.coordinates;
                updatedLocation.coordinates = `${lat}, ${lng}`;
            }
            setLocation(updatedLocation);
            if (location?.settlementAccount?.id) {
                (async () => await loadStripeAccount(location.settlementAccount.id))();
            }
        } else {
            setLocation({});
            setStripeAccount({});
        }
    }

    useEffect(() => {
        fetchLatestSettlementAccount()
    }, [provider.id, setLocation, setStripeAccount]);

    useEffect(() => {
        (async () => {
            if (isAdmin || viewAsProvider) return;
            const listProviders = await apiClient.get('/providers');
            setProviders(listProviders);
            const initialProvider = listProviders[0];
            actions.setProvider(initialProvider);
            actions.setLocation(initialProvider.location);
        })();
    }, [isAdmin, viewAsProvider]);

    const switchProvider = (name: string) => {
        if (!name) return;
        const found = providers.find((p) => p.name === name);
        actions.setProvider(found);
        actions.setLocation(found.location);
    };

    function setCurrentExternalBankDetails(stripeAccount: any){
        if(stripeAccount?.external_accounts) {
            stripeAccount?.external_accounts?.data?.map((bank: any): any => {
                if (bank?.default_for_currency) {
                    setCurrentBank(bank);
                }
            });
        } else {
            setCurrentBank(null);
        }
    }

    const handleAttachStripeAccount = async () => {
        if (currentStripeId) {
            return await attachStripeAccount(location.id, currentStripeId);
        }
    };

    const handleUpdateStripeId = async() => {
        let errors = [];
        if (!udpatingStripeId) {
            setUpdatingStripeId(true);
            return
        }
        setUdpateStripeIdProcessing(true);
        if(String(currentStripeId).trim() === String(stripeAccount?.id).trim()){
            toast.error("Please change stripe id");
        } else {
            if(!udpateStripeIdProcessing){
                const newupdatedLocation2 = await handleAttachStripeAccount()
                let newupdatedLocation = _.cloneDeep(newupdatedLocation2 || location);
                provider.location = newupdatedLocation
                actions.setLocation(newupdatedLocation);
                actions.setProvider(provider)
                fetchLatestSettlementAccount()
            }
        }
        setUdpateStripeIdProcessing(false);
        setUpdatingStripeId(false);
    }

    const handleSaveAccountChanges = async () => {
        try {
            const stripeAccountData = _.cloneDeep(stripeAccount);
            const accountPayload = {
                updatedData: {
                    business_profile: {
                        ...(stripeAccountData?.business_profile?.name && {
                            name: stripeAccountData?.business_profile?.name
                        }),
                        ...(stripeAccountData?.business_profile?.support_address && {
                            support_address: stripeAccountData?.business_profile?.support_address
                        }),
                        ...(stripeAccountData?.business_profile?.support_email && {
                            support_email: stripeAccountData?.business_profile?.support_email
                        }),
                        ...(stripeAccountData?.business_profile?.support_phone && {
                            support_phone: stripeAccountData?.business_profile?.support_phone
                        })
                    },
                    ...(stripeAccountData?.email && { email: stripeAccountData?.email }),
                    company: {
                        address: {
                            ...(stripeAccountData?.company?.address?.city && {
                                city: stripeAccountData?.company?.address?.city
                            }),
                            ...(stripeAccountData?.company?.address?.country && {
                                country: stripeAccountData?.company?.address?.country
                            }),
                            ...(stripeAccountData?.company?.address?.line1 && {
                                line1: stripeAccountData?.company?.address?.line1
                            }),
                            ...(stripeAccountData?.company?.address?.line2 && {
                                line2: stripeAccountData?.company?.address?.line2
                            }),
                            ...(stripeAccountData?.company?.address?.postal_code && {
                                postal_code: stripeAccountData?.company?.address?.postal_code
                            }),
                            ...(stripeAccountData?.company?.address?.state && {
                                state: stripeAccountData?.company?.address?.state
                            })
                        }
                    }
                }
            };

            if (stripeAccount?.id) {
                const updatedSettlementAccount = await updateSettlementAccountDetails(stripeAccount?.id, accountPayload, setSettlemntAccountUpdateProcessing);
                if(updatedSettlementAccount){
                    fetchLatestSettlementAccount() 
                }
            }
        } catch (error) {
            console.log("Error while updating settlement account details.")
        }
    }

    const updateExternalBank = async () => {
        if (!bankUpdating) {
            setBankUpdating(true);
            return
        }

        const bankPayload = {
            externalBankId: currentBank?.id,
            updatedData: {
                account_holder_name: currentBank?.account_holder_name
            }
        }

        try {
            const updatedSettlementExternalAccount = await updateSettlementExternalAccount(location?.settlementAccount?.id, bankPayload, setBankUpdateProcessing)
            if (updatedSettlementExternalAccount) {
                setCurrentBank(updatedSettlementExternalAccount)
            } else {
                setCurrentExternalBankDetails(stripeAccount);
            }
        } catch (err) {
            console.log("Error while updating external bank details.", err);
        } finally {
            setBankUpdating(false);
        }
    }

    const handleBankDetailsChange = (e: any) => {
        const { name, value } = e.target;
        setCurrentBank({ ...currentBank, [name]: value });
    }

    const handleCloseUpdateStripeId = () => {
        setUpdatingStripeId(false);
        setCurrentStripeId(stripeAccount?.id)
    }

    const handleCloseUpdateBank = () => {
        setBankUpdating(false);
        setCurrentExternalBankDetails(stripeAccount);
    }

    return (
        <Layout>
            {!provider?.location?.settlementAccount ? 
                <div className="border mb-1">
                    <div className="overview-heading">
                        <div className="heading-medium">No stripe account connected with this provider.</div>
                    </div>
                </div>
                :
                <div>
                    <div className="overview-heading">
                        <div className="heading-medium">Manage Bank</div>
                        {!isAdmin && providers?.length > 1 && (
                            <div style={{ width: '30%', float: 'left' }}>
                                <Autocomplete
                                    options={providers.map((p) => p.name)}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option, value) => value}
                                    onChange={(event: any, selectedProvider: any) => switchProvider(selectedProvider)}
                                    renderInput={(params) => <TextField {...params} label="Switch Provider" />}
                                />
                            </div>
                        )}
                        <div className='d-flex'>
                            {isAdmin && (
                                <>
                                    <PrimaryButton
                                        style={{ margin: '5px' }}
                                        label="View As Admin"
                                        onClick={() => {
                                            actions.setViewAsAdmin();
                                            history.push({ pathname: '/providers', state: { fromViewAsAdmin: true } });
                                        }}
                                    />
                                </>
                            )}
                            <div>
                                <PrimaryButton
                                    disabled={udpatingStripeId}
                                    style={{ margin: '5px' }}
                                    label="Save"
                                    onClick={() => {
                                        handleSaveAccountChanges();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {!!residualLocation('stripeAccount.id') ? (
                        <div className="border mb-1 mt-3">
                            <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                                {' '}
                                Manage Stripe Id
                            </h4>
                            <TextField
                                id="stripe-account-id"
                                // disabled={true}
                                marginTop={true}
                                label="Stripe Account ID"
                                fullWidth={true}
                                value={residualStripeAccount('id')}
                            />
                        </div>
                    ) : (
                        <div className="border mb-1 mt-3">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                                        Manage Stripe Id
                                    </h4>
                                </div>
                                <div className='bankupdate'>
                                    {udpatingStripeId && !udpateStripeIdProcessing && <AddButton
                                        label={"Cancel"}
                                        onClick={handleCloseUpdateStripeId}
                                    />}
                                    {!udpateStripeIdProcessing ? currentStripeId && <PrimaryButton
                                        label={!udpatingStripeId ? "Update Stripe ID" : "Save"}
                                        onClick={handleUpdateStripeId}
                                    /> : <div style={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </div>
                                    }
                                </div>
                            </div>
                            {currentStripeId ? <TextField
                                id="stripe-account-id"
                                disabled={!udpatingStripeId}
                                marginTop={true}
                                label="Stripe Account ID"
                                fullWidth={true}
                                onChange={(e: any) => { setCurrentStripeId(e.target.value) }}
                                value={currentStripeId}
                            /> : "Loading Stripe ID..."}
                        </div>
                    )}
                    <div className="border mb-1">
                        <div className='d-flex justify-content-between'>
                            <div>
                                <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                                    Current Bank Details
                                </h4>
                            </div>
                            <div className='bankupdate'>
                                {bankUpdating && !bankUpdateProcessing && <AddButton
                                    label={"Cancel"}
                                    onClick={handleCloseUpdateBank}
                                />}
                                {currentBank && !bankUpdateProcessing ?
                                    currentBank !== null && <PrimaryButton
                                        label={!bankUpdating ? "Update Bank" : "Save"}
                                        onClick={updateExternalBank}
                                    /> :
                                    currentBank !== null && bankUpdateProcessing && <div style={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </div>
                                }
                            </div>
                        </div>
                        {currentBank && currentBank !== null ?
                            <div>
                                <TextField
                                    disabled={true}
                                    id="stripe-bank-name"
                                    marginTop={true}
                                    label="Bank Name"
                                    fullWidth={true}
                                    value={currentBank?.bank_name}
                                />
                                <TextField
                                    disabled={true}
                                    id="stripe-bank-account-number"
                                    marginTop={true}
                                    label="Bank Account Number"
                                    fullWidth={true}
                                    type="Number"
                                    // helperText={bankUpdating && "Enter bank account number without space. E.g. 000123456789"}
                                    // onChange={(e: any) => bankUpdating && handleBankDetails(e)}
                                    value={currentBank?.last4}
                                />
                                <TextField
                                    disabled={true}
                                    id="stripe-bank-routing-number"
                                    marginTop={true}
                                    label="Bank Routing Number"
                                    fullWidth={true}
                                    // helperText={bankUpdating && "Enter bank routing number. E.g. 110000000"}
                                    // onChange={(e: any) => bankUpdating && handleBankDetails(e)}
                                    value={currentBank?.routing_number}
                                />
                                <TextField
                                    disabled={!bankUpdating}
                                    id="stripe-bank-holder-name"
                                    name="account_holder_name"
                                    marginTop={true}
                                    label="Bank Holder Name"
                                    fullWidth={true}
                                    // helperText={bankUpdating && "Enter bank routing number. E.g. 110000000"}
                                    onChange={bankUpdating && handleBankDetailsChange}
                                    value={currentBank?.account_holder_name}
                                />
                            </div>
                            : currentBank === null ? "No Bank attached with this stripe account." : "Loading Bank Details..."
                        }
                    </div>
                    {currentStripeId && <div className="border mb-1">
                        <h4 className="heading-small text-muted d-flex" style={{ marginLeft: 'auto' }}>
                            Other Account Details (Optional)
                        </h4>
                        <TextField
                            id="stripe-account-name"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Business or Company Name"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('business_profile.name')}
                            value={residualStripeAccount('business_profile.name')}
                        />
                        <TextField
                            id="stripe-account-email"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Business or Company Email"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('email')}
                            value={residualStripeAccount('email')}
                        />
                        <TextField
                            id="company-address-line1"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Company's Address Line1"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('company.address.line1')}
                            value={residualStripeAccount('company.address.line1')}
                        />
                        <TextField
                            id="company-address-line2"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Company's Address Line2"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('company.address.line2')}
                            value={residualStripeAccount('company.address.line2')}
                        />
                        <TextField
                            id="company-postal-code"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Company's Postal Code"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('company.address.postal_code')}
                            value={residualStripeAccount('company.address.postal_code')}
                        />
                        <TextField
                            id="company-city"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Company's City"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('company.address.city')}
                            value={residualStripeAccount('company.address.city')}
                        />
                        <TextField
                            id="company-state"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Company's State"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('company.address.state')}
                            value={residualStripeAccount('company.address.state')}
                        />
                        <TextField
                            id="company-country"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Company's Country"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('company.address.country')}
                            value={residualStripeAccount('company.address.country')}
                        />
                    </div>}
                    {currentStripeId && <div className="border mb-1">
                        <h4 className="heading-small text-muted" style={{ marginLeft: 'auto' }}>
                            Support Details for Account (Optional)
                        </h4>
                        <TextField
                            id="stripe-support-account-name"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Support Address"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('business_profile.support_address')}
                            value={residualStripeAccount('business_profile.support_address')}
                        />
                        <TextField
                            id="stripe-account-email"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Support Email"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('business_profile.support_email')}
                            value={residualStripeAccount('business_profile.support_email')}
                        />
                        <TextField
                            id="stripe-account-phone"
                            disabled={udpatingStripeId}
                            marginTop={true}
                            label="Support Phone Number"
                            fullWidth={true}
                            onChange={handleStripeAccountInput('business_profile.support_phone')}
                            value={residualStripeAccount('business_profile.support_phone')}
                        />
                    </div>}
                </div>
            }
        </Layout>
    )
}

export default BankInformation